import jquery, { param } from "jquery";
window.jQuery = window.$ = jquery;

// Bootstrap Framework
import "./scss/index.scss";
import "@popperjs/core";
import "bootstrap";

import "slick-carousel";
import hcSticky from "hc-sticky";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

import axios from "axios";
//#endregion

//#region stickybar
document.addEventListener("DOMContentLoaded", function () {
  new hcSticky(".stickybar", {
    stickTo: "body",
    stickyClass: "stickybar--sticked",
  });
});
//#endregion
//#region offcavas mobile
$("[data-trigger]").on("click", function () {
  var trigger_id = $(this).attr("data-trigger");
  $(trigger_id).toggleClass("show");
  $("body").toggleClass("offcanvas-active");
});
$(".close-icon").on("click", function (e) {
  $(".navbar-collapse").removeClass("show");
  $("body").removeClass("offcanvas-active");
});
//#endregion

//#region slider
$(function () {
  $(".slider").slick({
    arrows: false,
    centerMode: true,
    variableWidth: true,
  });
});
//#endregion

//#region form
$(function () {
  $(".form--mail").on("submit", function (e) {
    e.preventDefault();
    let params = $(this).serialize();
    axios
      .post($("meta[name=apiurl]").attr("content") + "?" + params)
      .then((res) => {
        console.log(res);
        $("#after-send").removeClass("d-none");
        $("#before-send").addClass("d-none");
      })
      .catch((err) => {
        alert(err);
        console.log(err);
      });
  });
});
//#endregion
